import { CORES_AGENDA, COR_AGENDA_DESABILITADO, COR_AGENDA_SELECIONADO } from "./Constants";

export const mudarNomeTab = (nome) => {
    document.title = nome;
}

export const pedirPermissaoNotificacao = (exibirNotificacao = () => { }) => {
    if (!("Notification" in window)) return;

    if (Notification.permission === 'granted') {
        // Permissão já concedida
        exibirNotificacao();
    } else if (Notification.permission !== 'denied') {
        // Pedir permissão ao usuário
        Notification.requestPermission().then(permission => {
            if (permission === 'granted') {
                exibirNotificacao();
            }
        });
    }
}

export const resetTab = () => {
    mudarNomeTab(global.gConfig.titulo_aplicacao);
}

export const gerarCalendariosScheduleX = () => {
    const cal = {}

    for (let i = 1; i <= CORES_AGENDA.length; i++) {
        cal[`cal${i}`] = {
            colorName: `cal${i}`,
            lightColors: {
                main: CORES_AGENDA[i - 1].barra,
                container: CORES_AGENDA[i - 1].fundo,
                onContainer: '#000', //texto
            },
        }
    }
    cal.disabled = {
        colorName: 'disabled',
        lightColors: {
            main: COR_AGENDA_DESABILITADO.barra,
            container: COR_AGENDA_DESABILITADO.fundo,
            onContainer: '#000',
        },
    }
    cal.selected = {
        colorName: 'selected',
        lightColors: {
            main: COR_AGENDA_SELECIONADO.barra,
            container: COR_AGENDA_SELECIONADO.fundo,
            onContainer: '#000',
        },
    }
    return cal;
}