import * as yup from 'yup';
import { AGENDAMENTO, ASSUNTO, CATEGORIA, DATA_HORA_INICIO, PARTICIPANTES, REDE, TEMPO_DURACAO } from './field-names';
import { TIPO_FUNCIONAMENTO_REDE } from '../../../common/Constants';

export default (strings) => {
    return yup.object().shape({
        [REDE]: yup
            .object()
            .nullable()
            .required(strings.campoObrigatorio),
        [CATEGORIA]: yup
            .object()
            .nullable()
            .required(strings.campoObrigatorio),
        [ASSUNTO]: yup
            .string()
            .trim()
            .min(5, strings.erroAssuntoMinimo5Caracteres)
            .max(255)
            .nullable()
            .required(strings.campoObrigatorio),
        [DATA_HORA_INICIO]: yup
            .date()
            .nullable()
            .when(REDE, {
                is: (val) => val && val.tipoFuncionamento === TIPO_FUNCIONAMENTO_REDE.CONVITE,
                then: yup
                    .date()
                    .nullable()
                    .typeError(strings.dataInvalida)
                    .min(new Date(), strings.erroDataPassada)
                    .required(strings.campoObrigatorio)
            }),
        [TEMPO_DURACAO]: yup
            .number()
            .nullable()
            .when(REDE, {
                is: (val) => val && val.tipoFuncionamento === TIPO_FUNCIONAMENTO_REDE.CONVITE,
                then: yup
                    .number()
                    .nullable()
                    .min(10, strings.minimo10Minutos)
                    .max(360, strings.maximo360Minutos)
                    .required(strings.campoObrigatorio)
            }),
        [PARTICIPANTES]: yup
            .array()
            .when(REDE, {
                is: (val) => val && val.tipoFuncionamento === TIPO_FUNCIONAMENTO_REDE.CONVITE,
                then: yup
                    .array()
                    .min(1, strings.erroParticipanteObrigatorio)
                    .required(),
            }),
        [AGENDAMENTO]: yup
            .object()
            .nullable()
            .when(REDE, {
                is: (val) => val && val.tipoFuncionamento === TIPO_FUNCIONAMENTO_REDE.AGENDA,
                then: yup
                    .object()
                    .nullable()
                    .required(strings.campoObrigatorio)
            })
    });
}