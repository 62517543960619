import {
    ASSUNTO,
    CATEGORIA,
    DATA_HORA_TERMINO,
    DATA_HORA_INICIO,
    PARTICIPANTES,
    REDE,
    TEMPO_DURACAO,
    AGENDADOR,
    AGENDAMENTO
} from "./field-names";

export const INITIAL_VALUES_AGENDAMENTO = {
    [ASSUNTO]: '',
    [REDE]: null,
    [CATEGORIA]: null,
    [PARTICIPANTES]: [],
    [DATA_HORA_INICIO]: null,
    [DATA_HORA_TERMINO]: null,
    [AGENDADOR]: null,
    [TEMPO_DURACAO]: '',
    [AGENDAMENTO]: null
}