export const PERFIL = {
    ADMINISTRADOR: 'administrador',
    ADMINISTRADOR_ESTADUAL: 'adminEstadual',
    ADMINISTRADOR_CONECTA: 'adminConecta',
};

const situacao = {
    CANCELADO: -10,
    CONVITES_ENVIADOS: 0,
    REAGENDADO: 10,
    CONFIRMADO: 20,
    EM_ANDAMENTO: 30,
    FINALIZADO: 40
}

export const SITUACAO_AGENDAMENTO = {
    SITUACAO: { ...situacao },
    LABEL: {
        [situacao.CANCELADO]: 'Cancelado',
        [situacao.CONVITES_ENVIADOS]: 'Convites enviados',
        [situacao.REAGENDADO]: 'Reagendado',
        [situacao.CONFIRMADO]: 'Confirmado',
        [situacao.EM_ANDAMENTO]: 'Em andamento',
        [situacao.FINALIZADO]: 'Finalizado',
    },
    GRID: {
        [situacao.CANCELADO]: {
            descricao: 'Cancelado',
            cor: '#F4CBCB'
        },
        [situacao.CONVITES_ENVIADOS]: {
            descricao: 'Enviado',
            cor: '#FAF7A9'
        },
        [situacao.REAGENDADO]: {
            descricao: 'Reagendado',
            cor: '#FBD7B1'
        },
        [situacao.CONFIRMADO]: {
            descricao: 'Confirmado',
            cor: '#B0D8B1'
        },
        [situacao.EM_ANDAMENTO]: {
            descricao: 'Em andamento',
            cor: '#CEE1F4'
        },
        [situacao.FINALIZADO]: {
            descricao: 'Finalizado',
            cor: '#AEAEAE'
        },
    },
}

export const PERMISSOES = {
    ADMINISTRATIVO: 'CONECTA_ADMINISTRATIVO',
    CONECTA: 'CONECTA_CONECTA',
}

export const MYME_TYPE_ARQUIVOS = {
    JPEG: 'image/jpeg',
    PNG: 'image/png',
    PDF: 'application/pdf',
    DOC: 'application/msword',
    DOCX: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    XLS: 'application/vnd.ms-excel',
    XLSX: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
}

export const MYME_TYPE_PERMITIDOS = Object.values(MYME_TYPE_ARQUIVOS);

export const VINCULO_PERFIL_ESTADUAL = 'E';

export const TIPO_FUNCIONAMENTO_REDE = {
    CONVITE: 'C',
    AGENDA: 'A'
}

export const EVENTOS_SOCKET = {
    ALTERAR_STATUS_ATENDIMENTO_VIDEO: 'alterar_status_atendimento_video',
    ALTERAR_STATUS_REGISTRO_ABERTO: 'alterar_status_atendimento_video',
    ATENDIMENTO_ENCERRADO: 'atendimento_encerrado',
    AVISO_DIGITANDO: 'aviso_digitando',
    CONNECTION: 'connection',
    DADOS_SALA: 'dados_sala',
    DADOS_USUARIO: 'dados_usuario',
    DISCONNECT: 'disconnect',
    ENVIAR_ANEXO: 'enviar_anexo',
    ENVIAR_AVISO_DIGITANDO: 'enviar_aviso_digitando',
    ENVIAR_MENSAGEM: 'enviar_mensagem',
    FINALIZAR_ATENDIMENTO: 'finalizar_atendimento',
    ID_SALA_ATENDIMENTO: 'id_sala_atendimento',
    LISTA_PRESENCA: 'lista_presenca',
    MENSAGEM_ENVIADA: 'mensagem_enviada',
    MENSAGENS_SALA: 'mensagens_sala',
    STATUS_ATENDIMENTO_VIDEO: 'status_atendimento_video',
    STATUS_ATENDIMENTO_VIDEO_INICIAL: 'status_atendimento_video_inicial',
    STATUS_REGISTRO_ABERTO: 'status_registro_aberto',
    USUARIO_DESCONETADO: 'usuario_desconectado',
    USUARIO_ONLINE: 'usuario_online'
}

const desfechos = {
    MANEJO_APS: 'M',
    ENCAMINHAMENTO_ESPECIALISTA: 'E',
    FINALIZADO: 'F',
    RETORNO_ONLINE: 'R'
}

export const DESFECHOS_ATENDIMENTO = [
    { descricao: 'Manejo na APS', id: desfechos.MANEJO_APS },
    { descricao: 'Encaminhamento ao especialista', id: desfechos.ENCAMINHAMENTO_ESPECIALISTA },
    { descricao: 'Finalizado', id: desfechos.FINALIZADO },
    { descricao: 'Retorno online', id: desfechos.RETORNO_ONLINE },
]

export const CORES_AGENDA = [
    { fundo: "#92CAF4", barra: "#92BDDC" },
    { fundo: "#F8C0BC", barra: "#DFB5B2" },
    { fundo: "#F6DA8E", barra: "#DDC98F" },
    { fundo: "#94D6B7", barra: "#94C5AF" },
    { fundo: "#E1B2ED", barra: "#CEAAD6" },
    { fundo: "#BDBFF7", barra: "#B3B5DF" },
    { fundo: "#ABCAF8", barra: "#A6BCDF" },
    { fundo: "#FAD4A7", barra: "#E0C4A3" },
    { fundo: "#C3E3AA", barra: "#B8CFA5" },
    { fundo: "#F4BCDA", barra: "#DCB2C8" },
    { fundo: "#D6CDFA", barra: "#C5BFE1" },
    { fundo: "#BBE6DC", barra: "#B1D2CA" },
];

export const COR_AGENDA_DESABILITADO = { fundo: "#E2E2E2", barra: "#B6B6B6" };
export const COR_AGENDA_SELECIONADO = { fundo: "#E2FFCB", barra: "#B6CCA5" };